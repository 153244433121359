'use client';
import LogoLoader from '@/components/loadingComponents/LogoLoader';
import { store } from '@/store/store';
import { useRef } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

export default function ReduxProvider({ children }) {
  const persistorRef = useRef();

  if (!persistorRef.current) {
    // Create the store and persistor instance the first time this component renders
    persistorRef.current = store.__persistor;
  }

  return (
    <Provider store={store}>
      <PersistGate loading={<LogoLoader />} persistor={persistorRef.current}>
        {children}
      </PersistGate>
    </Provider>
  );
}
