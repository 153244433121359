export const setCookie = (name, value, days = 30) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `; expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value}${expires}; path=/`;
};

export const getCookies = () => {
  const cookieString = document.cookie || '';
  return Object.fromEntries(cookieString.split('; ').map(c => c.split('=')));
};

export const removeCookie = name => {
  document.cookie = `${name}=; Max-Age=0; path=/;`;
};
