import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { HYDRATE, createWrapper } from 'next-redux-wrapper';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import alertSliceReducer from './alertSlice';
import authReducer from './authSlice';

const createNoopStorage = () => {
  return {
    getItem() {
      return Promise.resolve(null);
    },
    setItem(_key, value) {
      return Promise.resolve(value);
    },
    removeItem() {
      return Promise.resolve();
    },
  };
};

const combineReducer = combineReducers({
  authvalues: authReducer,
  alertpopup: alertSliceReducer,
});
const rootReducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    };
    return nextState;
  } else {
    return combineReducer(state, action);
  }
};

export const makeConfiguredStore = inReducer =>
  configureStore({
    reducer: inReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  });

export const makeStore = () => {
  const isServer = typeof window === 'undefined';
  if (isServer) {
    return makeConfiguredStore(rootReducer);
  } else {
    // we need it only on client side
    // const storage = require('redux-persist/lib/storage');

    const storage =
      typeof window !== 'undefined'
        ? require('redux-persist/lib/storage')
        : createNoopStorage();
    const persistConfig = {
      key: 'nextjs',
      whitelist: ['authvalues', 'alertpopup'], // make sure it does not clash with server keys
      storage: storage.default,
    };
    const persistedReducer = persistReducer(persistConfig, rootReducer);
    const store = makeConfiguredStore(persistedReducer);
    store.__persistor = persistStore(store); // Nasty hack
    return store;
  }
};

export const wrapper = createWrapper(makeStore);
export const store = makeStore();
